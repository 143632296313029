<template>
  <section class="account" v-if="user">
    <div class="setting click" @click="editUsername = true">
      <i class="icon fa-solid fa-user fa-xl"></i>
      <div class="content">
        <span class="title">Username</span>
        <span class="value">{{ user.username }}</span>
      </div>
      <i class="icon fa-solid fa-chevron-right fa-md"></i>
    </div>
    <div class="setting">
      <i class="icon fa-solid fa-envelope fa-xl"></i>
      <div class="content">
        <span class="title">Email</span>
        <span class="value">{{ user.email }}</span>
      </div>
    </div>
    <div class="setting click" @click="resetPassword = true">
      <i class="icon fa-solid fa-lock fa-xl"></i>
      <div class="content">
        <span class="title">Reset Password</span>
      </div>
      <i class="icon fa-solid fa-chevron-right fa-md"></i>
    </div>
    <l-prompt v-model="editUsername" bottom title="Edit username" okText="Save" :content="username" >
      <template slot="body">
        <div v-if="usernameLastChange >= 90" class="description">
          You can change your username only once per 90 days.
        </div>
        <div v-else class="description" style="color:#FF4757;">
          You can change your username again in {{ 90 - usernameLastChange }} days.
        </div>
        <l-input v-model="username" :disabled="usernameLastChange < 90" grow />
      </template>
      <template slot="actions">
        <button class="cancel" @click="editUsername = false">Cancel</button>
        <button v-if="usernameLastChange >= 90" class="ok" @click="changeUsername">
          <span v-if="!changeUsernameLoading">Change</span>
          <span v-else class="loading">
            <i class="fa-solid fa-spin fa-spinner"></i>
          </span>
        </button>
      </template>
    </l-prompt>
    <l-prompt
      v-model="resetPassword"
      title="Reset Password"
      okText="Reset"
      type="prompt"
      @ok="resetPasswordFunc"
    >
      <template slot="body">
        <div class="description" style="color: white">Do you want to reset your password?</div>
        <div class="description">
          You will receive an email with a link to reset your password.
        </div>
      </template>
    </l-prompt>
  </section>
</template>

<script>
import moment from 'moment';
import axios from 'axios';
export default {
  data: () => ({
    editUsername: false,
    username: '',
    changeUsernameLoading: false,
    resetPassword: false,
  }),
  computed: {
    user() {
      return this.$store.state.user;
    },
    usernameLastChange() {
      let output = 90;
      if (!this.user) return output;
      if (this.user.usernameChangedAt) {
        output = moment().diff(this.user.usernameChangedAt, 'days');
      }
      return output;
    }
  },
  mounted() {
    if (this.$route.query.resetPassword === 'success') {
      this.$notify({
        type: 'success',
        message: 'Your password has been reset!'
      });
    }
  },
  methods: {
    changeUsername() {
      if (this.usernameLastChange < 90) return;
      if (this.username === this.user.username) {
        this.$notify({
          type: 'error',
          title: 'Error',
          text: 'You cannot change your username to the same value.'
        });
        return this.editUsername = false;
      }
      this.changeUsernameLoading = true;
      axios.post(this.$api("/user/changeUsername"), { username: this.username }, {
        headers: { 
          Authorization: `Bearer ${this.$store.state.token}` 
        }
        })
        .then(response => {
          this.$store.commit("setUser", response.data);
          this.$notify({
            type: 'success',
            title: 'Success',
            text: 'Your username has been changed.'
          });
          this.editUsername = false;
          this.changeUsernameLoading = false;
          this.username = this.user.username;
        })
        .catch(error => {
          this.$notify({
            type: 'error',
            title: 'Error',
            text: error.response.data.message
          });
          this.editUsername = false;
          this.changeUsernameLoading = false;
        });
    },
    resetPasswordFunc() {
      this.resetPassword = false;
      axios.get(this.$api(`/user/recoverPassword?email=${this.user.email}`))
        .then(() => {
          this.$notify({
            type: 'success',
            text: 'We have sent you the email!'
          });
        })
        .catch(error => {
          this.$notify({
            type: 'error',
            title: 'Error',
            text: error.response.data.message
          });
        });
    }
  },
  watch: {
    user() {
      if (this.user) {
        this.username = this.user.username;
      }
    }
  }
}
</script>

<style>

</style>